<script setup>
definePageMeta({
  layout: "auth",
});

useHead({
  title: "Acceder | Chicupón",
});
</script>

<template>
  <AuthSignin />
</template>
