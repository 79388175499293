<script setup>
import { useAlertsStore } from "@/stores/alertsStore";

const supabase = useSupabaseClient();
const user = useSupabaseUser();

watchEffect(() => {
  if (user.value) {
    user.value.user_metadata.role === "admin" ||
    user.value.user_metadata.role === "business-admin"
      ? navigateTo("/dashboard")
      : navigateTo("/");
  }
});

const alertsStore = useAlertsStore();

const resetAlert = () => {
  alertsStore.alert = {
    show: false,
    type: "",
    message: null,
    linkText: "",
    href: "#",
  };
};

onMounted(() => {
  resetAlert();
});

const loading = ref("");

const handleLogin = async (fields) => {
  try {
    loading.value = true;
    const { error } = await supabase.auth.signInWithPassword({
      email: fields.email,
      password: fields.password,
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    const errorMessage =
      error.message === "Invalid login credentials"
        ? "Email o contraseña incorrectos"
        : error.message;
    useShowAlert("error", errorMessage, true);
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
  >
    <div class="mb-3 flex justify-center">
      <img class="h-10 w-auto" src="/logo.png" alt="" />
    </div>
    <AuthHeader title="Acceder a Chicupón" />

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <AlertSwitch class="mb-3" />
      <Form_
        incomplete-message="Lo sentimos, aun hay campos sin completar"
        @submit="handleLogin"
      >
        <div class="leading-4">
          <FormInput
            id="email"
            label="Email"
            type="email"
            name="email"
            validation="required|email"
            :validation-messages="{
              required: 'Email es requerido',
              email: 'Debes ingresar un email válido',
            }"
          />
          <div class="mb-2 mt-3">
            <FormInput
              label="Contraseña"
              type="password"
              name="password"
              validation="required|length:8,16"
              :validation-messages="{
                required: 'Contraseña es requerida',
                length:
                  'Contraseña debe tener mínimo de 8 y un máximo de 16 carácteres',
              }"
            />
          </div>
          <div class="text-right text-sm">
            <NuxtLink
              to="/auth/password-recovery"
              class="font-semibold text-indigo-600 hover:text-indigo-500"
              >Recuperar contraseña</NuxtLink
            >
          </div>

          <FormSubmitAuthButton
            :label="loading ? 'Accediendo...' : 'Acceder'"
          />
        </div>
      </Form_>

      <!-- <AuthFooter label="Crear un cuenta de acceso" to="/auth/signup" /> -->
    </div>
  </div>
</template>
